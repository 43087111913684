import { User } from 'src/app/core/interfaces/user';

/**
 * Storage Key enum
 */
export enum StorageKey {
  accessToken = 'accessToken',
  language = 'language',
  userData = 'userData',
}

/**
 * App Skel Storage interface
 */
export interface AppSkelStorage {
  /**
   * Defines a string variable to manage the authentication token
   */
  [StorageKey.accessToken]: string;

  /**
   * Defines a string variable to manage the app language
   */
  [StorageKey.language]: string;

  /**
   * Defines an User variable to manage the user data
   */
  [StorageKey.userData]: User;
}
