/**
 * Data Call interface
 */
export interface DataCall {
  /**
   * Defines a string variable to manage the type field
   */
  type: 'get' | 'post' | 'patch' | 'delete' | 'put';

  /**
   * Defines a string variable to manage the path field
   */
  path: string;

  /**
   * Defines a string Array variable to manage the params field
   */
  params?: string[];

  /**
   * Defines an any variable to manage the queryParams field
   */
  queryParams?: any;

  /**
   * Defines a string variable to manage the accessToken field
   */
  accessToken?: string;

  /**
   * Defines a string or any or Array variable to manage the body field
   */
  body?: any | string | [];

  /**
   * Defines a string variable to manage the storeName field
   */
  storeName?: string;
}
