import { HttpParameterCodec } from '@angular/common/http';

/**
 * Custom HttpParameterCodec
 * Workaround for https://github.com/angular/angular/issues/18261
 */
export class CustomHttpParameterCodec implements HttpParameterCodec {
  /**
   * Encode key
   *
   * @param k
   */
  encodeKey(k: string): string {
    return encodeURIComponent(k);
  }

  /**
   * Encode value
   *
   * @param v
   */
  encodeValue(v: string): string {
    return encodeURIComponent(v);
  }

  /**
   * Decode key
   *
   * @param k
   */
  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }

  /**
   * Decode value
   *
   * @param v
   */
  decodeValue(v: string): string {
    return decodeURIComponent(v);
  }
}

