import { HttpParameterCodec } from '@angular/common/http';

/**
 * Configuration Parameters interface
 */
export interface ConfigurationParameters {
  /**
   * Defines an any variable to manage the apiKeys field
   */
  apiKeys?: {[ key: string ]: string};

  /**
   * Defines a string variable to manage the username field
   */
  username?: string;

  /**
   * Defines a string variable to manage the password field
   */
  password?: string;

  /**
   * Defines a string variable to manage the accessToken field
   */
  accessToken?: string;

  /**
   * Defines a string variable to manage the basePath field
   */
  basePath?: string;

  /**
   * Defines a boolean variable to manage the withCredentials field
   */
  withCredentials?: boolean;

  /**
   * Defines a HttpParameterCodec variable to manage the encoder field
   */
  encoder?: HttpParameterCodec;

  /**
   * Defines a boolean variable to manage the production field
   */
  production?: boolean;
}

/**
 * Configuration interface
 */
export class Configuration {
  /**
   * Defines an any variable to manage the apiKeys field
   */
  apiKeys?: {[ key: string ]: string};

  /**
   * Defines a string variable to manage the username field
   */
  username?: string;

  /**
   * Defines a string variable to manage the password field
   */
  password?: string;

  /**
   * Defines a string variable to manage the accessToken field
   */
  accessToken?: string;

  /**
   * Defines a string variable to manage the basePath field
   */
  basePath?: string;

  /**
   * Defines a boolean variable to manage the withCredentials field
   */
  withCredentials?: boolean;

  /**
   * Defines a HttpParameterCodec variable to manage the encoder field
   */
  encoder?: HttpParameterCodec;

  /**
   * Defines a boolean variable to manage the production field
   */
  production?: boolean;

  /**
   * Constructor
   *
   * @param configurationParameters
   */
  constructor(configurationParameters: ConfigurationParameters = {}) {
    this.apiKeys = configurationParameters.apiKeys;
    this.username = configurationParameters.username;
    this.password = configurationParameters.password;
    this.accessToken = configurationParameters.accessToken;
    this.basePath = configurationParameters.basePath;
    this.withCredentials = configurationParameters.withCredentials;
    this.encoder = configurationParameters.encoder;
    this.production = configurationParameters.production;
  }

  /**
   * Select the correct content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   *
   * @param contentTypes - the array of content types that are available for selection
   */
  public selectHeaderContentType(contentTypes: string[]): string | undefined {
    if (contentTypes.length === 0) {
      return undefined;
    }

    const type = contentTypes.find((x: string) => this.isJsonMime(x));
    if (type === undefined) {
      return contentTypes[0];
    }
    return type;
  }

  /**
   * Select the correct accept content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct accept content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   *
   * @param accepts - the array of content types that are available for selection.
   */
  public selectHeaderAccept(accepts: string[]): string | undefined {
    if (accepts.length === 0) {
      return undefined;
    }

    const type = accepts.find((x: string) => this.isJsonMime(x));
    if (type === undefined) {
      return accepts[0];
    }
    return type;
  }

  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   *
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   */
  public isJsonMime(mime: string): boolean {
    const jsonMime = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
  }
}
