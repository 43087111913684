import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { StorageKey } from 'src/app/core/interfaces/storage';
import { TranslateService } from '@ngx-translate/core';

/**
 * Locale interface
 */
export interface Locale {
  /**
   * Defines a string variable to manage the lang field
   */
  lang: string;

  /**
   * Defines an any variable to manage the data field
   */
  data: any;
}

/**
 * Translate Service
 */
@Injectable({
  providedIn: 'root',
// eslint-disable-next-line indent
})
export class TranslationService {
  /**
   * Defines an any Array variable to manage the lang identifies
   */
  private readonly langIds: any = [];

  /**
   * Service Constructor
   *
   * @param translate
   */
  constructor(
    private readonly translate: TranslateService,
  ) {}

  /**
   * Initialize
   */
  init(): void {
    // add new langIds to the list
    this.translate.addLangs(['es']);

    // The method below can be used to get the device's chosen language so that the app matches it.
    const browserLang: string = this.translate.getBrowserLang();

    // Set whichever language is preferred
    this.translate.use('es');

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('es');
    moment.locale('es');
  }

  /**
   * Load Translation
   *
   * @param args: Locale[]
   */
  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach(locale => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  /**
   * Setup language
   *
   * @param lang: any
   */
  async setLanguage(lang): Promise<void> {
    if (lang) {
      // page freezes when using the temporary Lang drop-down
      // this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
      moment.locale(lang);
      await localStorage.set(StorageKey.language, lang);
    }
  }

  /**
   * Returns selected language
   */
  async getSelectedLanguage(): Promise<string> {
    return await localStorage.get(StorageKey.language) || this.translate.getDefaultLang();
  }
}
