import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  public async getItem(item: string) {
    const targetItem = await localStorage.getItem(item);
    return targetItem ? JSON.parse(targetItem) : null;
  }

  /**
   * 
   * @param item set as 'any' to allow numbers, strings or objects
   * @param key name of the item to be set
   */
  public setItem(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }
}
